<template>
  <div class="richtext-container">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="曲谱名称:" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入名称"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="作者:" prop="author">
        <el-input
          v-model="form.author"
          placeholder="请输入名称"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="收费类型:" prop="free_type">
        <el-select
          v-model="form.free_type"
          placeholder="收费类型"
          clearable
          style="width: 150px"
          class="filter-item"
        >
          <el-option label="免费" :value="1" />
          <el-option label="会员免费" :value="2" />
          <el-option label="会员收费" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="价格:" prop="price" v-if="form.free_type != 1">
        <el-input
          v-model="form.price"
          style="width: 200px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item>

      <el-form-item label="曲谱" prop="resources">
        <UploadList
          v-model="form.resources"
          width="350px"
          height="150px"
        ></UploadList>
      </el-form-item>
      <!-- <el-form-item label="曲谱视频:" prop="video">
        
         <el-radio v-model="radio" label="1">本地上传</el-radio>
         <el-radio v-model="radio" label="2">网络链接</el-radio>
        <uploadVideo
        v-if="radio==1"
         v-model="form.video"
          width="350px"
          height="150px">
        </uploadVideo>
        <el-input
          v-else
          v-model="form.video"
          placeholder="请输入名称"
          style="width: 550px,display:inline-block;"
        ></el-input>

      </el-form-item> -->

      <el-form-item label="序号" prop="sort_weight">
        <el-input
          v-model="form.sort_weight"
          placeholder="请输入曲谱序号"
          style="width: 350px"
          type="Number"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadList from "@/components/UploadList/index.vue";
import request from "@/utils/request";
import uploadVideo from '@/components/UploadVideo/index.vue';
export default {
  components: {
    UploadList,
    uploadVideo
  },
  data() {
    return {
      radio: '1',
      btnLoading: false,
      form: {
        id: "",
        name: "",
        author: "",
        price: "",
        free_type: "",
        resources: [],
        video:"",
        sort_weight:''
      },
      rules: {
        name: [
          { required: true, message: "曲谱名称不能为空", trigger: "blur" },
        ],
        price: [{ required: true, message: "价格不能为空", trigger: "blur" }],
        author: [{ required: true, message: "作者不能为空", trigger: "blur" }],
        free_type: [
          { required: true, message: "请选择收费类型", trigger: "change" },
        ],
        sort_weight: [
          { required: true, message: "曲谱序号不能为空", trigger: "blur" },
        ],
        resources: [
          {
            type: "array",
            required: true,
            message: "曲谱不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id != "undefined") {
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    getDetail(id) {
      request({
        url: "/api/backend/musicScore/detail",
        method: "get",
        params: {
          id,
        },
      }).then((response) => {
        this.form = response.data;
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/musicScore/store",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
}
</style>
